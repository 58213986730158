import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import History from '../views/History.vue'
// import Webcam from '../views/Webcam.vue'

Vue.use(VueRouter)

const routes = [
	{
		path     : '/',
		// component: Home
		component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
	},
	{
		path     : '/scan',
		// component: Webcam
		component: () => import(/* webpackChunkName: "webcam" */ '../views/Webcam.vue')
	},
	{
		path     : '/history',
		// component: History
		component: () => import(/* webpackChunkName: "history" */ '../views/History.vue'),
		props    : {filter: 'complete'}
	},
	{
		path     : '/history-all',
		// component: History
		component: () => import(/* webpackChunkName: "history" */ '../views/History.vue'),
		props    : {filter: 'all'}
	},
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router

import Vue from 'vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import App from './App.vue'
import router from './router'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// extra CSS
require('./assets/styles/webcam-demo.css');
require('./assets/styles/webcam-overlay.css');

Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
